import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout-template'
import { Link } from 'gatsby';

class AppTemplate extends React.Component {
  render() {
    const content = get(this.props, 'data.contentfulApp')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location} >
        <div>
          <Helmet title={`${content.title} | ${siteTitle}`} />
          <div className="wrapper">
            <h1 className="section-headline">{content.title}</h1>
            <Link to={`/page/${content.privacyPolicy.slug}`}>{content.privacyPolicy.title}</Link><br/>
            <Link to={`/page/${content.termsConditions.slug}`}>{content.termsConditions.title}</Link>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AppTemplate;

export const pageQuery = graphql`
    query AppBySlug($slug: String!) {
        contentfulApp(slug: {eq: $slug}) {
          title
          privacyPolicy {
            slug,title
          }
          termsConditions {
            slug,title
          }
        }
    }
`
